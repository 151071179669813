<template>
  <div class="mx-8">
    <div class="d-flex justify-center text-h5">BELANJA PRIORITAS</div>

    <div class="d-flex justify-center text-h6">RKBMN TAHUN {{ tahun }}</div>

    <div class="d-flex justify-center text-h6">
      {{ infoDetailSatker.label }}
    </div>

    <div
      class="d-flex justify-center mb-2"
      v-if="listPrioritas.data && listPrioritas.data.length > 0"
    >
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            color="error"
            class="mr-1"
            @click="showDialogEdit()"
            >mdi-pencil-box</v-icon
          >
        </template>
        <span>Edit Data</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            color="error"
            class="mr-1"
            @click="dialogDelete = true"
            >mdi-delete</v-icon
          >
        </template>
        <span>Hapus Data</span>
      </v-tooltip>
    </div>

    <v-divider></v-divider>

    <div class="mb-3"></div>

    <div
      v-if="listPrioritas.data && listPrioritas.data.length > 0"
      class="mb-8"
    >
      <div class="text-h6">
        {{ detailPrioritas.info }}
      </div>
      <div class="text-h7">Keterangan: {{ detailPrioritas.keterangan }}</div>

      <div class="mb-5"></div>

      <label class="subtitle-2">
        DAFTAR LAMPIRAN
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              class="success ml-2"
              v-bind="attrs"
              v-on="on"
              @click="dialogAddLampiran = true"
              ><v-icon>mdi-plus-thick</v-icon></v-btn
            >
          </template>
          <span>Tambah Lampiran</span>
        </v-tooltip>
      </label>

      <div class="mb-3"></div>

      <v-card outlined>
        <v-data-table
          :headers="headersLampiran"
          :items="detailPrioritas.lampiran"
          disable-sort
          disable-pagination
          hide-default-footer
        >
          <template v-slot:item.nomor="{ item }">
            <span>{{ detailPrioritas.lampiran.indexOf(item) + 1 }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  class="mr-2"
                  @click="openDocument(item)"
                  >mdi-eye</v-icon
                >
              </template>
              <span>Lihat</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  class="mr-2"
                  @click="showDialogEditDocument(item)"
                  >mdi-pencil-box</v-icon
                >
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  @click="showDialogDeleteDocument(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Hapus</span>
            </v-tooltip>
          </template>

          <template v-slot:no-data>
            <label>Tidak ada data</label>
          </template>
        </v-data-table>
      </v-card>

      <div class="mb-5"></div>

      <label class="subtitle-2">
        DAFTAR ITEM
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              class="success ml-2"
              v-bind="attrs"
              v-on="on"
              @click="dialogAddItem = true"
              ><v-icon>mdi-plus-thick</v-icon></v-btn
            >
          </template>
          <span>Tambah Item</span>
        </v-tooltip>
      </label>

      <v-text-field
        label="Cari Item"
        v-model="itemSearch"
        class="my-4"
        append-icon="mdi-magnify"
        outlined
        hide-details
      ></v-text-field>

      <v-card outlined>
        <v-data-table
          :headers="
            checkAccess('303', userData.akses)
              ? headersItemReference
              : headersItem
          "
          :items="detailPrioritas.items"
          :search="itemSearch"
          disable-pagination
          hide-default-footer
        >
          <template v-slot:item.nomor="{ item }">
            <span>{{ detailPrioritas.items.indexOf(item) + 1 }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  class="mr-2"
                  @click="showDialogEditItem(item)"
                  >mdi-pencil-box</v-icon
                >
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                  @click="showDialogDeleteItem(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Hapus</span>
            </v-tooltip>
          </template>

          <template v-slot:item.reference="{ item }">
            <v-btn class="primary" small @click="toDetailPage(item)"
              >Lihat</v-btn
            >
          </template>

          <template v-slot:no-data>
            <label>Tidak ada data</label>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <div v-else class="d-flex justify-center">
      <span>
        Tidak ada data belanja perioritas, silahkan menambahkan data
        <v-btn x-small class="success ml-1" fab @click="dialogAdd = true"
          ><v-icon>mdi-plus-thick</v-icon></v-btn
        >
      </span>
    </div>

    <v-dialog v-model="dialogAdd" width="400">
      <v-card>
        <v-card-title class="text-h5 success white--text">
          Tambah Data
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              label="Info"
              v-model="dataAdd.info"
              :rules="requiredRules"
              required
            ></v-text-field>
            <v-textarea
              label="Keterangan"
              v-model="dataAdd.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogAdd = false"> Batal </v-btn>
          <v-btn color="primary" text @click="addDataPrioritas()">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEdit" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Edit Data
        </v-card-title>

        <br />

        <v-card-text>
          <v-form>
            <v-text-field label="Info" v-model="dataEdit.info"></v-text-field>
            <v-textarea
              label="Keterangan"
              v-model="dataEdit.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="editData()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Data
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus seluruh
          <strong>Data Belanja Prioritas</strong> RKBMN Tahun
          {{ tahun }} ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDelete = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteData()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddLampiran" width="400">
      <v-card>
        <v-card-title class="text-h5 success white--text">
          Tambah Lampiran
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-file-input
              label="File"
              v-model="dataLampiran.file"
              accept=".pdf, .png, .jpg,"
              hide-details
              :rules="requiredRules"
              required
            ></v-file-input>
            <span class="d-flex justify-end caption"
              >Ukuran File Maksimal 30MB</span
            >

            <v-text-field
              label="Info Dokumen"
              v-model="dataLampiran.info"
              :rules="requiredRules"
              required
            ></v-text-field>

            <v-textarea
              label="Keterangan"
              v-model="dataLampiran.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogAddLampiran = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="addLampiran()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditLampiran" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Edit Lampiran
        </v-card-title>

        <br />

        <v-card-text>
          <v-form>
            <v-file-input
              label="File"
              v-model="dataEditLampiran.file"
              accept=".pdf"
              hide-details
            ></v-file-input>
            <span class="d-flex justify-end caption"
              >Ukuran File Maksimal 30MB</span
            >

            <v-text-field
              label="Info Dokumen"
              v-model="dataEditLampiran.info"
            ></v-text-field>

            <v-textarea
              label="Keterangan"
              v-model="dataEditLampiran.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEditLampiran = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="editLampiran()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteLampiran" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Lampiran
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus
          <strong> {{ dataDeleteLampiran.info }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDeleteLampiran = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteLampiran()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddItem" width="400">
      <v-card>
        <v-card-title class="text-h5 success white--text">
          Tambah Item
        </v-card-title>

        <br />

        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              label="Item"
              v-model="dataAddItem.label"
              :rules="requiredRules"
              required
            >
            </v-text-field>
            <v-text-field
              label="Jumlah"
              v-model="dataAddItem.jumlah"
              type="number"
              :rules="requiredRules"
              required
            ></v-text-field>

            <v-textarea
              label="Keterangan"
              v-model="dataAddItem.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogAddItem = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="addItem()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditItem" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Edit Item
        </v-card-title>

        <br />

        <v-card-text>
          <v-form>
            <v-text-field label="Item" v-model="dataEditItem.label">
            </v-text-field>
            <v-text-field
              label="Jumlah"
              v-model="dataEditItem.jumlah"
              type="number"
            ></v-text-field>
            <v-textarea
              label="Keterangan"
              v-model="dataEditItem.keterangan"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEditItem = false">
            Batal
          </v-btn>
          <v-btn color="primary" text @click="editItem()"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteItem" width="400">
      <v-card>
        <v-card-title class="text-h5 error white--text">
          Hapus Item
        </v-card-title>

        <br />

        <v-card-text
          >Apakah anda yakin menghapus Item
          <strong> {{ dataDeleteItem.label }} </strong> ?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDeleteItem = false">
            Tidak
          </v-btn>
          <v-btn color="primary" text @click="deleteItem()"> Ya </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" color="success" timeout="1000">
      {{ snackbarText }}

      <template v-slot:action>
        <v-btn fab x-small color="white" @click="snackbar = false">
          <v-icon color="error">mdi-close-thick</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tahun: this.$route.params.tahun,
      id: this.$route.params.id,
      headersLampiran: [
        { text: "No.", value: "nomor", width: "70px" },
        { text: "Lampiran", value: "info" },
        { text: "Keterangan", value: "keterangan" },
        { text: "Aksi", value: "actions" },
      ],
      headersItem: [
        { text: "No.", value: "nomor", width: "70px", sortable: false },
        { text: "Item", value: "label" },
        { text: "Jumlah", value: "jumlah" },
        { text: "Keterangan", value: "keterangan", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ],
      headersItemReference: [
        { text: "No.", value: "nomor", width: "70px", sortable: false },
        { text: "Item", value: "label" },
        { text: "Jumlah", value: "jumlah" },
        { text: "Keterangan", value: "keterangan", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
        { text: "Referensi", value: "reference", sortable: false },
      ],
      dialogAdd: false,
      dataAdd: {
        info: "",
        keterangan: "",
      },
      dialogEdit: false,
      dataEdit: {
        id: "",
        info: "",
        keterangan: "",
      },
      dialogDelete: false,
      dialogAddLampiran: false,
      dataLampiran: {
        info: "",
        file: [],
        keterangan: "",
      },
      dialogEditLampiran: false,
      dataEditLampiran: {
        id: "",
        info: "",
        file: [],
        keterangan: "",
      },
      dialogDeleteLampiran: false,
      dataDeleteLampiran: {
        id: "",
        info: "",
      },
      dialogAddItem: false,
      dataAddItem: {
        label: "",
        jumlah: "",
        keterangan: "",
      },
      dialogEditItem: false,
      dataEditItem: {
        id: "",
        label: "",
        jumlah: "",
        keterangan: "",
      },
      dialogDeleteItem: false,
      dataDeleteItem: {
        id: "",
        label: "",
      },
      itemSearch: "",

      valid: true,
      requiredRules: [(v) => !!v || "Data harus diisi"],
      snackbar: false,
      snackbarText: "",
    };
  },
  watch: {
    dialogAdd() {
      if (!this.dialogAdd) {
        this.$refs.form.reset();
        this.dataAdd.info = "";
        this.dataAdd.keterangan = "";
      }
    },
    dialogAddLampiran() {
      if (!this.dialogAddLampiran) {
        this.$refs.form.reset();
        this.dataLampiran.info = "";
        this.dataLampiran.file = [];
        this.dataLampiran.keterangan = "";
      }
    },
    dialogAddItem() {
      if (!this.dialogAddItem) {
        this.$refs.form.reset();
        this.dataAddItem.label = "";
        this.dataAddItem.jumlah = "";
        this.dataAddItem.keterangan = "";
      }
    },
  },
  computed: {
    listPrioritas() {
      return this.$store.getters.listPrioritas;
    },
    detailPrioritas() {
      return this.$store.getters.detailPrioritas;
    },
    infoDetailSatker() {
      return this.$store.getters.infoDetailSatker;
    },
    userData() {
      return this.$store.getters.userData;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.params = {
        rkbmn: this.id,
      };
      this.$store.dispatch("getListPrioritas", this.params).then((res) => {
        this.$store.dispatch("getInfoDetailSatker", res.params.query.satker);
        res.data.forEach((element) => {
          this.$store.dispatch("getDetailPrioritas", element._id);
        });
      });
    },
    addDataPrioritas() {
      this.$refs.form.validate();
      if (this.dataAdd.info != "") {
        if (this.valid) {
          const data = {
            idrkbmn: this.id,
            info: this.dataAdd.info,
            keterangan: this.dataAdd.keterangan,
          };
          this.$store.dispatch("addPrioritas", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogAdd = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "Info tidak boleh kosong";
      }
    },
    showDialogEdit() {
      this.dataEdit.id = this.detailPrioritas._id;
      this.dataEdit.info = this.detailPrioritas.info;
      this.dataEdit.keterangan = this.detailPrioritas.keterangan;
      this.dialogEdit = true;
    },
    editData() {
      const data = {
        id: this.dataEdit.id,
        info: this.dataEdit.info,
        keterangan: this.dataEdit.keterangan,
      };

      this.$store.dispatch("editPrioritas", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEdit = false;
        }
      });
    },
    deleteData() {
      const data = {
        id: this.detailPrioritas._id,
      };
      this.$store.dispatch("deletePrioritas", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        this.dialogDelete = false;
        if (value.hasil == "success") {
          this.init();
        }
      });
    },
    addLampiran() {
      this.$refs.form.validate();
      if (this.dataLampiran.info != "" && this.dataLampiran.file != "") {
        if (this.valid) {
          const data = new FormData();
          data.set("id", this.detailPrioritas._id);
          data.set("info", this.dataLampiran.info);
          data.set("file", this.dataLampiran.file);
          data.set("keterangan", this.dataLampiran.keterangan);

          this.$store.dispatch("addLampiranPrioritas", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogAddLampiran = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "File dan Info Dokumen tidak boleh kosong";
      }
    },
    openDocument(item) {
      this.$store
        .dispatch("getLinkDocumentPrioritas", item.file)
        .then((res) => {
          window.open(res.data, "_blank");
        });
    },
    showDialogEditDocument(item) {
      this.dataEditLampiran.id = item._id;
      this.dataEditLampiran.info = item.info;
      this.dataEditLampiran.keterangan = item.keterangan;
      this.dialogEditLampiran = true;
    },
    editLampiran() {
      const data = new FormData();
      data.set("id", this.dataEditLampiran.id);
      data.set("info", this.dataEditLampiran.info);
      data.set("file", this.dataEditLampiran.file);
      data.set("keterangan", this.dataEditLampiran.keterangan);

      this.$store.dispatch("editLampiranPrioritas", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEditLampiran = false;
        }
      });
    },
    showDialogDeleteDocument(item) {
      this.dataDeleteLampiran.id = item._id;
      this.dataDeleteLampiran.info = item.info;
      this.dialogDeleteLampiran = true;
    },
    deleteLampiran() {
      const data = {
        id: this.dataDeleteLampiran.id,
      };
      this.$store.dispatch("deleteLampiranPrioritas", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogDeleteLampiran = false;
        }
      });
    },
    addItem() {
      this.$refs.form.validate();
      if (this.dataAddItem.label != "" && this.dataAddItem.jumlah != "") {
        if (this.valid) {
          const data = {
            id: this.detailPrioritas._id,
            label: this.dataAddItem.label,
            jumlah: this.dataAddItem.jumlah,
            keterangan: this.dataAddItem.keterangan,
          };

          this.$store.dispatch("addItemBelanja", data).then((value) => {
            this.snackbar = true;
            this.snackbarText = value.pesan;
            if (value.hasil == "success") {
              this.init();
              this.dialogAddItem = false;
            }
          });
        }
      } else {
        this.snackbar = true;
        this.snackbarText = "Item dan Jumlah tidak boleh kosong";
      }
    },
    showDialogEditItem(item) {
      this.dataEditItem.id = item._id;
      this.dataEditItem.label = item.label;
      this.dataEditItem.jumlah = item.jumlah;
      this.dataEditItem.keterangan = item.keterangan;
      this.dialogEditItem = true;
    },
    editItem() {
      const data = {
        id: this.dataEditItem.id,
        label: this.dataEditItem.label,
        jumlah: this.dataEditItem.jumlah,
        keterangan: this.dataEditItem.keterangan,
      };

      this.$store.dispatch("editItemBelanja", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogEditItem = false;
        }
      });
    },
    showDialogDeleteItem(item) {
      this.dataDeleteItem.id = item._id;
      this.dataDeleteItem.label = item.label;
      this.dialogDeleteItem = true;
    },
    deleteItem() {
      const data = {
        id: this.dataDeleteItem.id,
      };
      this.$store.dispatch("deleteItemBelanja", data).then((value) => {
        this.snackbar = true;
        this.snackbarText = value.pesan;
        if (value.hasil == "success") {
          this.init();
          this.dialogDeleteItem = false;
        }
      });
    },
    checkAccess(nameKey, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i]["_id"] == nameKey) {
          return myArray[i];
        }
      }
    },

    toDetailPage(item) {
      this.$router.push({
        name: "d_item",
        params: {
          tahun: this.tahun,
          id: this.id,
          produk: item.label,
          idProduk: item._id,
        },
      });
    },
  },
};
</script>